import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useBasket } from '../../providers/basket'

const msg = {
  '/permanent-makeup/': {
    text: 'Buy One, Receive a Free Top-Up',
    ctaURL: '/promotions/',
    ctaText : 'More Promotions'
  },
  '/flash-sales/': {
    text: 'Discover Our Latest PROMOTIONS',
    ctaURL: '/promotions/',
    ctaText : 'Shop Now'
  },
  // '/laser-hair-removal/': {
  //   text: 'Get 60% Off Any Single Laser Treatment with code LASER60',
  //   ctaURL: '/promotions/',
  //   ctaText : 'More Promotions'
  // },
  // '/treatments/face/': {
  //   text: 'Get 25% off facials with code SPRINGFACE',
  // },
  // '/treatments/body/': {
  //   text: 'Get 50% off body with code NYBODY50',
  // },
  // '/skin-boosters/': {
  //   text: 'Get 25% off injectables with code NYINJECT',
  // },
  // '/dermal-fillers/': {
  //   text: 'Get 25% off injectables with code NYINJECT',
  // },
  // '/anti-wrinkle-injections/': {
  //   text: 'Get 25% off injectables with code NYINJECT',
  // },
  // '/permanent-makeup/': {
  //   text: 'Get 25% off permanent makeup for bookings in June',
  // },
}

export default function FixedBanner({ router }) {
  const [dismiss, setDismissed] = useState<string | boolean>(true)
  const { addItem } = useBasket()
  //console.log('router', router.pathname)
  const m = msg[router.asPath]
    ? msg[router.asPath]
    : {
        text: "Get 3, pay for 2 - Mix&Match Your Favourite Treatments",
        ctaURL: null,//'/promotions/',
        ctaText : 'More Promotions'
      }

  // text: 'BLACK FRIDAY is here! 50% off all treatments with code BF50',
  //  useEffect(() => {
  //   const value = localStorage.getItem('cl.offer.banner6')
  //   setDismissed(!!value)

  //   setTimeout(() => {
  //     setDismissed(true)
  //   }, 60 * 1000)
  // }, [])

  // if (dismiss || router.pathname.includes('booking')) {
  //   return null
  // }

  const close = () => {
    localStorage.setItem('cl.offer.consultations', 'y')
    setDismissed(true)
  }

  if (!m) return null

  return (
    <div className="bg-brand-600">
      <div className="mx-auto max-w-7xl py-1 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg bg-brand-800 p-2">
              <MegaphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">
              <span className="md:hidden">{m.text}</span>
              <span className="hidden md:inline">{m.text}</span>
            </p>
          </div>
          {/* ACTION BUTTON */} 
          {m.ctaURL &&
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                href={m.ctaURL}
                passHref
                className="w-full md:w-auto flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-brand-600 shadow-sm hover:bg-brand-50"
              >
                {m.ctaText}
              </Link>
            </div>
          }
          {/* CLOSE BUTTON */}
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              onClick={close}
              className="-mr-1 flex rounded-md p-2 hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
